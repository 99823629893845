<!-- eslint-disable -->

<template>
  <v-container fluid>
    <v-toolbar-title>{{combotabletitle}}</v-toolbar-title>
  <v-row no-gutters>
    <!-- ***** Update Table ***** -->
      <v-btn
        color="green"
        dark
        small 
        class="mt-2 mr-4"
        @click='UpdateTableData'
        >
        Update Table
      </v-btn>
    <!-- ***** Save Settings ***** -->
    <v-dialog v-model="dialog2" max-width="320px" persistent>
      <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="light-blue"
        dark
        small 
        class="mt-2 mr-4"
        v-bind="attrs"
        dense
        v-on="on"
        @click="savesettingsformkey += 1"
        >Save Settings  </v-btn>
      </template>
      <save-settings-form
        title="Save Report Settings"
        v-on:cancelentry="onCancel2"
        v-on:saveentry="onSaveSettings"
        :key="savesettingsformkey"
      >
      </save-settings-form>
    </v-dialog>
    <!-- ***** Create Entries ***** -->
    <v-dialog v-model="dialog1" max-width="320px" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="light-blue"
          dark
          small
          class="mt-2 mr-4"
          v-bind="attrs"
          v-on="on"
          @click="datalogentryformkey += 1"
        >Create New Entries</v-btn>
      </template>
      <datalog-entry-form
        title="Create Meter Entries"
        :pheaders="pheaders"
        v-on:cancelentry="onCancel"
        v-on:savedismissentry="onSaveDismiss"
        :key="datalogentryformkey"
      >
      </datalog-entry-form>
    </v-dialog>
    <!-- ***** Toggle Datasource row/column view mode ***** -->
    <v-btn
      color="green lighten-5"
      small
      class="mt-2 mr-4 black--text"
      @click='toggleDSview'
      >
      {{ dsviewtext }}
    </v-btn>
    <!-- ***** Show Summary View ***** -->
    <v-btn v-if="select_dscolumn"
      color="green lighten-5"
      small
      class="mt-2 mr-4 black--text"
      @click='toggleSummaryView'
      >
      {{ dssummaryviewtext }}
    </v-btn>
    <!-- ***** Show Filters ***** -->
    <v-btn
      color="green lighten-5"
      small
      class="mt-2 mr-4 black--text"
      @click='toggleFilters'
      >
      {{ filterbtntext }}
    </v-btn>
    <!-- ***** Clear Datasources ***** -->
    <v-btn
      color="green lighten-5"
      small
      class="mt-2 mr-4 black--text"
      @click='clearDataSources'
      >
      Clear Datasources
    </v-btn>
    <!-- ***** Delete Saved Report ***** -->
    <v-btn v-if="id > 0"
      color="red lighten-1"
      small
      dark
      class="mt-2 mr-4"
      @click='onDeleteSavedReport'
      >
      Delete This Report
    </v-btn>
    <!-- ***** Debug Instance ***** -->
    <v-btn
      color="red"
      dense
      text 
      @click='debuginstance++'
      >
      Debug Instance
    </v-btn>
    </v-row>
    <!-- *********************************** -->
    <!-- ***** Filter / Settings Array ***** -->
    <!-- *********************************** -->
    <template>
      <div :key=showf v-if="showfilters">
      <v-row  no-gutters>
        <v-col cols="3">
          <v-select
            label="Organization"
            v-on:change="populateSites(sel_org)"
            v-model="sel_org"
            :items="cb_orgs"
            item-text="name"
            item-value="uid"
            outlined
            dense
            class="mt-4"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
            label="Site"
            v-on:change="populateDataSources"
            v-model="sel_site"
            :items="cb_sites"
            item-text="name"
            item-value="uid"
            outlined
            dense
            class="mt-4"
          ></v-select>
        </v-col>
      </v-row>

    <!-- ***** Datasources ***** -->
      <v-row no-gutters>
    <!-- ***** Meter Datasource ***** -->
        <v-col cols="3">
          <v-select
            v-model="sel_dsmeter_index"
            :items="cb_dsources"
            label="Meter Data Source"
            item-text="text"
            item-value="id"
            outlined
            dense
          ></v-select>
        </v-col>
    <!-- ***** Usage Datasources ***** -->
        <v-col cols="3">
          <v-select
            v-model="sel_dsusage_index"
            :items="cb_dsources"
            label="Usage Data Source"
            item-text="text"
            item-value="id"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <!-- Switch between Preset and Custom Date Range modes -->
        <v-col cols="3">
          <v-btn
            class="mr-3"
            elevation="1"
            fab
            small
            color="success"
            @click="sw_dmode = ! sw_dmode"
          >
            <v-icon v-if="sw_dmode">
              mdi-calendar-multiple
            </v-icon>
            <v-icon v-else>
              mdi-dns
            </v-icon>
          </v-btn> -->
          {{sw_dmodelabel}}
        </v-col>

        <v-col 
          v-if="!sw_dmode"
          cols="2">
          <v-select
            v-model="sel_drange"
            :items="cb_drange"
            label="Date Range"
            item-text="name"
            item-value="id"
            outlined
            color="green"
            red--text
            dense
            return-object
          ></v-select>
        </v-col>

      <!-- Start Date -->
        <v-col cols="2"
          v-if="sw_dmode"
        >
      <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="true"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="datastartdate"
            label="Start Date"
            readonly
            outlined
            dense
            color="green"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="datastartdate"
          no-title
          scrollable
          @input="menu = false"
        >
        </v-date-picker>
      </v-menu>
      </v-col>

      <!-- End Date -->
      <v-col cols="2"
        v-if="sw_dmode"         
        >
      <v-menu
        ref="menu2"
        v-model="menu2"
        :close-on-content-click="true"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dataenddate"
            label="End Date"
            readonly
            outlined
            dense
            color="green"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="dataenddate"
          no-title
          scrollable
          @input="menu = false"
        >
        </v-date-picker>
      </v-menu>
      </v-col>
      </v-row>
      </div>
      <div v-else>
        <v-divider> </v-divider>
      </div>
    </template>
    
<!-- **************************************** -->
<!-- *********** DATA TABLE HERE ************ -->
<!-- **************************************** -->

 <v-data-table
    dense
    :headers="headers"
    :items="datatable_data"
    :footer-props="{
      'items-per-page-options':[15, 30, 50, -1]
    }"
    sort-by="saveTime"
    :sort-desc="false"
  >
    <template v-slot:item.actions="{ item }">
      <!-- Delete Manual Entry on this row (NOT the auto-logged entry) -->
      <v-icon
        small
        @click="onDeleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>

  <v-dialog v-model="confirm" max-width="300px">
    <v-card>
      <v-card-title class="headline">Delete Entry?</v-card-title>
      <v-card-text>Are you sure you want to delete this entry?</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="confirmDelete(false)">Cancel</v-btn>
        <v-btn color="green darken-1" text @click="confirmDelete(true)">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  </v-container>
</template>

<script>

/* eslint-disable */
//************************
// JAVASCRIPT
//************************
import DataSourceService from '../../services/DataSourceService'
import CrmService from '../../services/CrmService'
import helperMixin from '@/mixins/helperMixin'
import moment from 'moment'
import DatalogService from '../../services/DatalogService'
import ConfigService from '../../services/ConfigService'
import SettingsGroupService from '../../services/SettingsGroupService'
import DatalogEntryForm from '../../components/forms/DatalogEntryForm.vue'
import SaveSettingsForm from '../../components/forms/SaveSettingsForm.vue'
import { EventBus } from '../../event-bus.js'
import { mapState, mapMutations } from 'vuex'
var SG = require('../../../server/models/m_settingsgroup')
var GS = require( '../../models/m_groupsettings')

export default {
  name: 'MeterChecks',
  components: {DatalogEntryForm, SaveSettingsForm},
  mixins: [helperMixin],
  props: { 
    id: {
      type: String,
      default: '0'
  }},

//************************
// DATA
//************************
  data () { return {
    debuginstance: 0,
    todayOverride: "",
    dialog1: false,
    dialog2: false,
    showf: 0,
    confirm: false,
    itemtodelete: null,
    datalogentryformkey: 0,
    savesettingsformkey: 0,
    menu1: false,
    menu2: false,
    combotabletitle: 'Meter Check Table (Base)',
    weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    // Chart Series
    //********** Data Table Data */
    //The following is the init for the primary view option (Comparison)
    init_headers: [
      { text: 'Timestamp',sortable: true, value: 'saveTime', class: "black--text subtitle-1", align: "start"},
      { text: 'Meter Datasource',sortable: false, value: 'meterTotal', class: "black--text subtitle-1", align: "start"},
      { text: 'Usage Datasource',sortable: false, value: 'usageTotal', class: "black--text subtitle-1", align: "start"},
      { text: 'Comparison Results',sortable: false, value: 'comparison', class: "black--text subtitle-1", align: "start"},
      { text: 'Actions', value: 'actions', sortable: false, class: "black--text subtitle-1" }
    ],
    //The following columns support the single datasource column view
    select_dscolumn: false,  // Set this to true to setup the following headers
    init_headers_dscolumn: [
      { text: 'Timestamp',sortable: true, value: 'saveTime', class: "black--text subtitle-1", align: "start"},
      { text: 'Datasource',sortable: true, value: 'text', class: "black--text subtitle-1", align: "start"},
      { text: 'Value',sortable: true, value: 'value', class: "black--text subtitle-1", align: "start"},
      { text: 'Actions', value: 'actions', sortable: false, class: "black--text subtitle-1" }
    ],
    //The following supports the summary view
    select_summaryview: false,  // Set this to switch to the summary view
    init_headers_dssummary: [
      { text: 'Start Date',sortable: true, value: 'startDate', class: "black--text subtitle-1", align: "start"},
      { text: 'End Date',sortable: true, value: 'endDate', class: "black--text subtitle-1", align: "start"},
      { text: 'Datasource',sortable: true, value: 'text', class: "black--text subtitle-1", align: "start"},
      { text: 'Period Entries',sortable: true, value: 'entryCount', class: "black--text subtitle-1", align: "start"},
      { text: 'Period Sum',sortable: true, value: 'periodSum', class: "black--text subtitle-1", align: "start"},
      { text: 'Daily Avg',sortable: true, value: 'dailyAvg', class: "black--text subtitle-1", align: "start"},
      { text: 'Daily Min',sortable: true, value: 'dailyMin', class: "black--text subtitle-1", align: "start"},
      { text: 'Daily Max',sortable: true, value: 'dailyMax', class: "black--text subtitle-1", align: "start"},
    ],



    headers: [],
    pheaders: [],
    ssfields: [
      {text: "Report Name", value: "Combo Table"} ],
    sel_dsmeter_index: null,
    sel_dsusage_index: null,

    datatable_data: [],

    //********** Filters / Settings Data */
    sgTypeId: 104,  // Settings Group type of this component 
    sgTypeName: "Meter Check Table",
    reportname: 'Meter Check Table',
    showfilters: 'false',
    startdate: null,
    enddate: null,
    datastartdate: '',
    dataenddate: '',
    sel_org: {},
    cb_orgs: [],
    sel_site: {},
    cb_sites: [],
    // DataSources
    cb_dsources: [],
    // Time/Date
    sw_dmode: false,  // false = Preset , true = Custom
    sw_dmodelabel: 'Date Range Mode: Preset',
    sel_drange: {name: 'Last Week', id: '0'},
    cb_drange: [
      {name: 'This Week', id: '0'},
      {name: 'Last Week', id: '1'},
      {name: 'Today', id: '2'},
      {name: 'Yesterday', id: '3'},
      {name: 'This Month', id: '4'},
      {name: 'Last Month', id: '5'},
      {name: 'This Quarter', id: '6'},
      {name: 'Last Quarter', id: '7'},
      {name: 'This Year', id: '8'},
      {name: 'Last Year', id: '9'},
      ],
  }
},
//************************
// WATCH
//************************
watch: {
  debuginstance (val) {
  // this.$emit('updateCVDrawer', true)
  debugger
  },
  sel_drange (val) {
    // debugger
  },
  // U
  sel_dsmeter_index(val) {
    this.UpdateTableStructure()
  },
  sel_dsusage_index(val) {
    this.UpdateTableStructure()
  },
  sel_org (val) {
    // New Org selected - so list the sites for that customer
    // debugger
  },
  sw_dmode (val) {
    this.sw_dmodelabel = !val ? 'Date Range Mode: Preset' : 'Date Range Mode: Custom'
    // if (val) {
    //   const today = '2020-11-06' // initialize "today" to newest data date
    //   let daterange = this.GetWeekRange2(today)
    //   this.datastartdate = daterange[2]
    //   this.dataenddate = today
    //   }
  },

},
//************************
// COMPUTED
//************************
computed: {
  filterbtntext () {
    const fbt = this.showfilters ? 'Hide Filters' : 'Show Filters'
    return fbt
  },
  dsviewtext () {
    const dsv = this.select_dscolumn ? 'Datasources in Rows' : 'Datasources in Cols'
    return dsv
  },
  dssummaryviewtext () {
    const dsv = this.select_summaryview ? 'Show Entries' : 'Show Summaries'
    return dsv
  },

},
//************************
// METHODS
//************************
methods: {
  forceRerender1 () {
    this.datalogentryformkey += 1
  },
  toggleFilters() {
    this.showfilters = !this.showfilters
    this.showf += 1
  },
  toggleDSview () {
    this.select_dscolumn = !this.select_dscolumn
    this.UpdateTableStructure()
  },
  toggleSummaryView () {
    this.select_summaryview = !this.select_summaryview
    this.UpdateTableStructure()
  },
  async clearDataSources() {
    this.sel_dsmeter_index = undefined
    this.sel_dsusage_index = undefined 
    this.UpdateTableStructure()
    this.UpdateTableData
  },
  async onDeleteSavedReport() {
    try {
    SettingsGroupService.deleteSettingsGroup(this.id)
      // Update the Saved Reports Nav Drawer
      EventBus.$emit('updateCVDrawer', true)
      alert ("This Saved Report was deleted!")
    } catch (err) {
      console.log('ComboChart > deleteSettingsGroup ERROR = ', err.message);
    }
  },
  confirmDelete(answer) {
      if (answer) { 
        this.DeleteItem() }
      else { this.itemtodelete = null }
      this.confirm = false 
  },  
  onDeleteItem (item) {
    this.confirm = true
    this.itemtodelete = item
  },
  DeleteItem() {
    const itemtodelete = this.itemtodelete
    const index = this.datatable_data.indexOf(itemtodelete)
    try { const entries = DatalogService.deleteEntry(itemtodelete.valueId)
      this.datatable_data.splice(index, 1)
      this.itemtodelete = null
    } catch (err) {
    this.error = err.message
    console.log('ComboTable > onDeleteItem (delete error)= ', this.error)
    }
    },

//************ Handler for saving current settings (Called from SaveSettingsForm component)
  async onSaveSettings (newsgName) {
    try {
      this.dialog2 = false
      // <TBD> Validate that adequate settings are available to save 
      //       If not, then raise error.
      const userUid = this.$store.state.loggedin.userUid
      const reportname = newsgName
      const sel_org = this.sel_org
      const sel_site = this.sel_site
    // Datasources
      const sel_dsmeter = this.cb_dsources[this.sel_dsmeter_index]
      const sel_dsusage = this.cb_dsources[this.sel_dsusage_index]
    // Date ranges
      const sw_dmode = this.sw_dmode
      const datastartdate = this.datastartdate
      const dataenddate = this.dataenddate
      const sel_drange = this.sel_drange
    // Settings group
      const sgtypeid = this.sgTypeId
      const sgtypename = this.sgTypeName
      //
      var settings = new GS.SGMeterCheckTable(reportname,true, sel_org ,sel_site, sel_dsmeter, sel_dsusage, sw_dmode, [datastartdate,dataenddate], sel_drange, 20)
      // debugger
      const newsg = await SettingsGroupService.createSettingsGroup(sgtypeid, sgtypename, userUid, settings)
      EventBus.$emit('updateCVDrawer', true)

      console.log("ComboTable > onSaveSettings: newsg = ", newsg)
      // Create Saved Reports Link

    } catch (err) {
      console.log('ComboTable > onSaveSettings ERROR = ', err.message);
    }
    },

// Cancel out the save settings dialog
  onCancel2 () {
    this.dialog2 = false
    },

  //************ Load the saved settings designated by the PK sgid
  async loadSettings(sgid) {
    try {
      const jsonsettings = await SettingsGroupService.getSettingsGroupById(sgid)
      const settings = JSON.parse(jsonsettings.sgSettings)
      if (settings.sgTypeId != this.sgTypeId) throw 'Loaded Settings Group TypeID Mismatch - this component requires sgTypeID = ' + this.sgTypeId
      // Set the settings for this component
      this.reportname = "(SAVED): \"" + settings.reportName + "\""
      this.sw_dmode = settings.dateRangeMode
      this.sel_drange = settings.presetDateRange
      this.datastartdate = settings.customDateRange[0]
      this.dataenddate = settings.customDateRange[1]
      this.selectOrg(settings.organization)
      this.selectSite(settings.site)
      this.selectDataSources(settings.ds_meter, settings.ds_usage) // no auto-select in MeterChecks report
      this.UpdateTableStructure()
      this.UpdateTableData()
      if (this.showfilters != settings.showFilters) this.toggleFilters()
    } catch (err) {
      console.log('ComboTable > loadSettings ERROR = ', err.message);
    }
  },

  //************ Handler for saving new entries (Called from DatalogEntryForm component)
  onSaveDismiss (entry) {
    this.UpdateTableData()
  },
  onCancel () {
    this.UpdateTableData()
    // console.log('onCancel: this.headers (after adjust) = ', this.headers)
    this.dialog1 = false
  },

// populate organization selector with those orgs that are accessible to the
//  current user 
  async populateOrganizations() {
    try {
      let cborgs = [];
      const loggedin = this.$store.state.loggedin
      const current_customer = this.$store.state.current_customer
      const orgs = await CrmService.customerSelectAll(loggedin, current_customer);
      cborgs = orgs.map(o => ({ name: o.organizationName, uid: o.customerUid }));
      // <TBD> The following selection needs to be made from logged in user
      // debugger
      this.cb_orgs = cborgs
      this.sel_org = this.cb_orgs[0].uid;
      // console.log(' > populateOganizations: cb_orgs = ', this.cb_orgs); 
      this.populateSites(this.sel_org);
    } 
    catch (err) {
      console.log('ComboTable > populateOrganizations ERROR = ', err.message);
    }
  },

  // Validate that org we want to (programmatically) select is in the select list
  //  Used when recalling saved settings (maybe the list of orgs is different now)
  selectOrg(organization) {
  try {  
    const orgs = this.cb_orgs 
    let orgselected = false
    for (let org of orgs) {
      if (organization == org.uid) {
        this.sel_org = org.uid
        orgselected = true
      }
    }
    if (!orgselected) { throw "Organization to select is not available !"}
    
    }
    catch (err) {
      console.log('ComboTable > selectOrg ERROR = ', err.message);
    }
  },

// Populate site associated with the Organization provided as param
  async populateSites(organization) {
    try {
      let cbsites = [];
      const sites = await CrmService.siteSelectByCustomerUid(organization);
      // debugger
      if (sites.length == 0) {throw "No site found for current organization!"}
      cbsites = sites.map(s => ({ name: s.siteName, uid: s.siteUid }));
      this.cb_sites = cbsites
      this.selectSite(cbsites[0].uid);
      // console.log('ComboTable > populateSites = ', this.cb_sites); 
      this.populateDataSources(cbsites[0].uid);
    } 
    catch (err) {
      console.log('ComboTable > populateSites ERROR = ', err.message);
    }
  },

// Set the selected site based on parameter (Uid)
  selectSite(siteuid) {
    try {
      // debugger
    // Search through the list of available sites to select from
      const sites = this.cb_sites
      let siteselected = false
      for (let site of sites) {
        if (siteuid == site.uid)
        { this.sel_site = site.uid 
          siteselected = true
        }
      }
      if (!siteselected) { throw "Site to select is not available !"}
    } catch (err) {
      console.log('ComboTable > selectSite ERROR = ', err.message);
    }
  },

// ***** Populate all datasources for the current site).  
// *****  arr_of_dsources_to_select specifies which ones to select, if any
  async populateDataSources(siteuid) {
    try {
      const dsrc = await DataSourceService.dsGetBySite(siteuid)
      // debugger
      this.cb_dsources = []
      this.sel_dsources = []
      let item, index = 0
      // debugger
      for (let item of dsrc) {
        // Note that the second item in this object is what v-data-table returns when
        //  an item is returned via v-model
        let tdsrc = {};
        if (item.suppressUI > 0) continue
        tdsrc.text = item.dataSourceName + " (" + item.units + ")";
        tdsrc.id = index;
        tdsrc.uid = item.dataSourceUid;
        tdsrc.units = item.units;
        tdsrc.sortable = true;
        // tdsrc.value = ''
        tdsrc.value = "value" + "_" + index.toString();
        tdsrc.class = "black--text subtitle-1";
        this.cb_dsources.push(tdsrc);
        this.sel_dsources.push(index);
        tdsrc["valuen"] = tdsrc.value;
        index++;
      }
      const cid = this.id
      if (cid > 0) {
        // Selections will be made from the saved settings
        this.loadSettings(cid)
      }
      // console.log('cb_dsources= ', this.cb_dsources, ' sel_dsources= ', this.sel_dsources)
      // debugger
    } 
    catch (err) {
      console.log('ComboTable > populateDataSources ERROR = ', err.message);
    }
  },

// ***** Select datasources - must be in the select list
  selectDataSources (select_dsmeter, select_dsusage) {
    try {
      // Search through the list of available datasources to select from
      const available_datasources = this.cb_dsources
      let ds1_available, ds2_available = false
      for (let i = 0; i < available_datasources.length-1; i++) {
        if (available_datasources[i].uid == select_dsmeter.uid) {
          ds1_available = true
          this.sel_dsmeter_index = i
        }
        if (available_datasources[i].uid == select_dsusage.uid) {
          ds2_available = true
          this.sel_dsusage_index = i
        }
      }
      if (!(ds1_available && ds2_available)) {
        throw "One or both Datasource(s) in saved report are not in the current configuration!"
      } 
        // successful selection of both datasources

    } catch (err) {
      console.log('ComboTable > selectDataSources ERROR = ', err.message);
    }
  },

  //******* UpdateTableStructure
  UpdateTableStructure() {
    this.datatable_data = []  // Clear the datatable
    this.headers = []
    let pheaders = []
    // Setup Headers based on selections
    // debugger
    if (!this.select_dscolumn) { 
    // Primary view Option - datasources & comparison results
      for (let i=0 ; i < this.init_headers.length ; i++) {
        this.headers.push(this.init_headers[i])
        }
    // console.log ("this.headers[1].value = ", this.headers[1].value)
    pheaders.push(this.headers[0])
    pheaders.push(this.headers[1])
    pheaders[1].uid = this.cb_dsources[this.sel_dsmeter_index].uid
    pheaders.push(this.headers[4])
    // debugger
    this.pheaders = pheaders
    } 
    else if (this.select_dscolumn && !this.select_summaryview) { 
    // Show a Single datasource column, show entries, each entry shows datasource type name
      for (let i=0 ; i < this.init_headers_dscolumn.length ; i++) {
        this.headers.push(this.init_headers_dscolumn[i])
      }
    } 
    else {
    // Must be selecting summaries
      for (let i=0 ; i < this.init_headers_dssummary.length ; i++) {
        this.headers.push(this.init_headers_dssummary[i])
      }
    }
    // debugger
    // console.log('headers= ', this.headers)
  },

  //******* UpdateTableData
  async UpdateTableData() {
    this.datatable_data = [] // Clear the data from the datatable
    // Headers are assumed to already be set up at this point
    // Determine data Range
    const config = await ConfigService.settingsGetOverview()
    let today = config.todayOverride
    if (today=="") { today = moment().format('YYYY-MM-DD') }
    let index = this.sel_drange.id
    let sw_dmode = this.sw_dmode
    let startdate, enddate, daterange
  // debugger
    //******* Which Date Range Mode? ********/
    if (!sw_dmode) {
      // Preset Mode - set startdate/enddate
      // debugger
      switch (Number.parseInt(index)) {
        case 0: { // This Week
          daterange = this.GetWeekRange2(today)
          startdate = daterange[0]
          enddate = daterange[1]
          break;
        }
        case 1: { // Last Week
          daterange = this.GetWeekRange2(today)
          startdate = daterange[2]
          enddate = daterange[3]
          break;
        }
        case 2: { // Today
          startdate = moment(today).format('YYYY-MM-DD')
          enddate = startdate
          break;
        }
        case 3: { // Yesterday
          startdate = moment(today).subtract(1,'days').format('YYYY-MM-DD')
          enddate = startdate
          break;
        }
        case 4: { // This Month
          daterange = this.GetMonthRange(today)
          startdate = daterange[0]
          enddate = daterange[1]
          break;
        }
        case 5: { // Last Month
          daterange = this.GetMonthRange(today)
          startdate = daterange[2]
          enddate = daterange[3]
          break;
        }
        case 6: { // This Quarter
          daterange = this.GetQuarterRange(today)
          startdate = daterange[0]
          enddate = daterange[1]
          break;
        }
        case 7: { // Last Quarter
          daterange = this.GetQuarterRange(today)
          startdate = daterange[2]
          enddate = daterange[3]
          break;
        }
        case 8: { // This Year
          daterange = this.GetYearRange(today)
          startdate = daterange[0]
          enddate = daterange[1]
          break;
        }
        case 9: { // Last Year
          daterange = this.GetYearRange(today)
          startdate = daterange[2]
          enddate = daterange[3]
          break;
        }
        // this.startdate = startdate
        // this.enddate = enddate
      }
    } else {
      // Custom Mode - user selects start and end dates
      startdate = this.datastartdate
      enddate = this.dataenddate
    }
  // debugger

    // Get the data and plug into the Table
  try {
    this.combotabletitle = this.reportname + " (" + startdate + " - " + enddate + ")"
    let dsmeter = this.cb_dsources[this.sel_dsmeter_index]
    let dsusage = this.cb_dsources[this.sel_dsusage_index]
    this.datatable_data = []
    // Meter Readings first (manual), with intervening sums.  Timestamps come from meter readings.
    const meterentries = await DatalogService.getEntries(startdate, enddate, dsmeter.uid)
    // Usage entries (autolog) column second, containing the sums of entries that fall between the above meter timestamps 
    const usageentries = await DatalogService.getEntries(startdate, enddate, dsusage.uid)
    if (meterentries != null && meterentries.length > 1) {
      // We have at least two meter entries, it's OK if there are no usage entries, that is part of the analysis
      let previousentry = meterentries[0]
      let usageentriesinterval = [], datatable_data = []
      let metersum, diff, comparison, ss
      // Loop on meter entries - we are focused on intervals
      for (let i = 1; i < meterentries.length; i++) {
        usageentriesinterval = this.filterDsEntries(previousentry.saveTime, meterentries[i].saveTime, usageentries)
        // Calculate usage from the interval between the surrounding meter entries
        metersum = meterentries[i].value - previousentry.value
        // Calculate usage from the sum of usage entries that fall inside the interval
        let sumofvalues = 0
        for (let usageentry of usageentriesinterval) {
          sumofvalues += parseInt(usageentry.value)
        }
        diff = sumofvalues - metersum
        if (diff > 0) ss = diff.toString() + " " + this.cb_dsources[1].units + " more than Meter"
        if (diff < 0) ss = diff.toString() + " " + this.cb_dsources[1].units + " less than Meter"
        if (diff == 0) ss = " the same " + this.cb_dsources[1].units + " as Meter!"
        comparison = "Usage recorded " +  ss
        // Set data table with values
        datatable_data.push({saveTime: previousentry.saveTime, meterTotal: metersum, usageTotal: sumofvalues, comparison: comparison, valueId: previousentry.valueId})
        previousentry = meterentries[i]
        }
        // Final row
        datatable_data.push({saveTime: previousentry.saveTime, meterTotal: '-', usageTotal: '-', comparison: '(end)'})
        this.datatable_data = datatable_data
      } else {
        // Not enough meterentries to do this report
        alert(" Not enough meter entries in range to run this report!")
        return
      }


    } catch (err) {
    this.error = err.message
    console.log('ComboTable > UpdateTableData: error = ', this.error)
    }
  }, // end -- Update Data

  //***** Filter the provided array entries so they fall between (startdatetime <= returnedentries < enddatetime)
  filterDsEntries(startdatetime, enddatetime, dsentries) {
    let returnentries = []
    for (let entry of dsentries)
    {
      if (moment(entry.saveTime).isSameOrAfter(startdatetime) && moment(entry.saveTime).isBefore(enddatetime))
      returnentries.push(entry)
    }
    return returnentries

  },

}, // End - METHODS

//************************
// LIFECYCLE HOOKS
//************************
created () {
  // debugger
  this.populateOrganizations(); // Kicks off initialization  
  this.showfilters = true    // This setting of showfilters is to force the Vue responsive rendering to detect a change when settings are loaded.  I tried a lot of things before coming up with this hack.
},

} // Export default end bracket

</script>

/******************************/
/*******  LOCAL STYLES  *******/
/******************************/
 
<style lang="scss" scoped>
#table > .v-data-header .v-text {
  color: rgb(248, 2, 2);
    font-size: 14px ;
  }

  .v-toolbar__title {
    font-size: 22px;
  }

</style>